import React, { memo } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'

import Img from '../Img'
import NoImg from '../NoImg'
import Like from '../Like'
import './Card.sass'
import { LIKE_TYPES } from '../../constants'

const Card = ({ type, slug, title, excerpt, image, tags, types, date, likes, like, refetch }) => {
  const handleTagClick = (e, slug) => {
    e.stopPropagation()
    const link = `/${type}/tags/${slug}`
    navigate(link)
  }
  const handleCardClick = (e) => {
    e.stopPropagation()
    const link = `/${type}/${slug}`
    navigate(link)
  }
  const _renderCardImage = () => {
    if (image && image.sourceUrl) {
      const { sourceUrl, title, altText } = image
      return <Img src={sourceUrl} alt={altText} title={title}/>
    }
    return <NoImg />
  }
  // const _renderTypes = () => {
  //   if (types && types.length > 0) {
  //     return types.map(type => {
  //       return <span key={type.value} onClick={(e) => handleTagClick(e, type.value)}>{`#${type.label} `}</span>
  //     })
  //   }
  //   return null
  // }
  const _renderTags = () => {
    if (tags && tags.length > 0) {
      return tags.map(tag => {
        return <span key={tag.slug} onClick={(e) => handleTagClick(e, tag.slug)}>{`#${tag.name} `}</span>
      })
    }
    return null
  }

  // const _renderTagsOrTypes = () => {
  //   if (tags) {
  //     return <div className="tags">{_renderTags()}</div>
  //   }
  //   // return <div className="types">{_renderTypes()}</div>
  // }
  return (
    <div className="cardLink" onClick={handleCardClick}>
      <article className="card column">
        <div className="cardImage">
          { _renderCardImage() }
        </div>
        <div className="cardContent column">
          <div className="title jp-accent regular">{ title }</div>
          <div className="date jp-accent small">{date}</div>
          <p className="jp-accent regular">
            { excerpt || '' }
          </p>
          <div className="cardFooter flex justify-between small">
            <div className="tags small">{_renderTags()}</div>
            {
              likes
                ? (
                  <Like
                    slug={slug}
                    likes={likes}
                    like={like}
                    type={LIKE_TYPES.BLOG}
                    refetch={refetch}
                  />
                )
                : null
            }
          </div>
        </div>
      </article>
    </div>
  )
}
export default memo(Card)

Card.propTypes = {
  type: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  image: PropTypes.object,
  tags: PropTypes.array,
  types: PropTypes.array,
  date: PropTypes.string,
  likes: PropTypes.number,
  like: PropTypes.bool,
  refetch: PropTypes.func
}
Card.defaultProps = {

}
