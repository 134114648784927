import React, { memo } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'

const SectionTag = ({ type, name, slug, isActive }) => {
  const handleTagClick = () => {
    if (!isActive) {
      const link = `/${type}/tags/${slug}`
      navigate(link)
    }
  }
  return (
    <li className={`sectionTag${isActive ? ' active' : ''}`} onClick={handleTagClick}>{name}</li>
  )
}

export default memo(SectionTag)

SectionTag.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired
}
