import React, { memo } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { PAGINATION_SIZE } from '../../constants'
import './Pagination.sass'

const Pagination = ({ currentPage, maxPages, type }) => {
  // If there is only one page, do not calculate or render anything
  if (maxPages <= 1) return null

  const _noPushNextBtn = () => {
    if (currentPage >= maxPages) {
      return ('noPushNextBtn')
    }
  }
  const _noPushPrevBtn = () => {
    if (currentPage - 1 === 0) {
      return ('noPushPrevBtn')
    }
  }

  const toPrevPage = () => {
    const prevPage = currentPage && currentPage > 1 ? currentPage - 1 : 1
    if (prevPage === 1) {
      navigate(`/${type}`)
    } else {
      navigate(`/${type}/page/${prevPage}`)
    }
  }
  const toNextPage = () => {
    const nextpage = currentPage && currentPage < maxPages ? currentPage + 1 : currentPage
    navigate(`/${type}/page/${nextpage}`)
  }
  const selectPage = (number) => {
    if (number === 1) {
      navigate(`/${type}`)
    } else {
      navigate(`/${type}/page/${number}`)
    }
  }

  const _renderNumber = (number) => {
    if (number === currentPage) return <span key={number} className="page_number current_page">{currentPage}</span>
    return (
      <span
        role="button"
        tabIndex="-1"
        className="page_number"
        key={number}
        onClick={() => { selectPage(number) } }
        onKeyPress={() => { selectPage(number) } }
      >
        {number}
      </span>
    )
  }

  const _createNumbers = () => {
    const halfFloor = Math.floor(PAGINATION_SIZE / 2)
    const halfCeil = Math.ceil(PAGINATION_SIZE / 2)
    // console.log('page', page, 'maxPages', maxPages)
    // When number of pages is less than or equal to the pagination max size
    // or when page number is less than or equal to half the pagination max size
    // Just print the number of pages
    const numbers = []
    if (maxPages <= PAGINATION_SIZE || currentPage <= halfCeil) {
      if (maxPages <= PAGINATION_SIZE) {
        for (let i = 1; i <= maxPages; i++) {
          // numbers.push(<NumberBtn number={i} currentPage={currentPage} onClick={selectPage} key={i}/>)
          numbers.push(_renderNumber(i))
        }
        return numbers
      } else {
        for (let i = 1; i <= PAGINATION_SIZE; i++) {
          // numbers.push(<NumberBtn number={i} currentPage={currentPage} onClick={selectPage} key={i}/>)
          numbers.push(_renderNumber(i))
        }
        return numbers
      }
    } else {
      // When the pagination max size -1 add to the page number is less than or equal to the total number of pages
      if (currentPage + (PAGINATION_SIZE - halfFloor) <= maxPages) {
        if (currentPage - halfFloor > 0) {
          for (let i = currentPage - halfFloor; i <= currentPage + halfFloor; i++) {
            // numbers.push(<NumberBtn number={i} currentPage={currentPage} onClick={selectPage} key={i}/>)
            numbers.push(_renderNumber(i))
          }
          return numbers
        } else {
          for (let i = currentPage; i <= currentPage + (PAGINATION_SIZE - halfFloor); i++) {
            // numbers.push(<NumberBtn number={i} currentPage={currentPage} onClick={selectPage} key={i}/>)
            numbers.push(_renderNumber(i))
          }
          return numbers
        }
      } else {
        if (currentPage) {
          for (let i = maxPages - (PAGINATION_SIZE - 1); i <= maxPages; i++) {
            // numbers.push(<NumberBtn number={i} currentPage={currentPage} onClick={selectPage} key={i}/>)
            numbers.push(_renderNumber(i))
          }
          return numbers
        }
      }
    }
  }

  return (
    <div className="pagination">
      <div className="inner flex accent">
        <div
          role="button"
          tabIndex="-2"
          className={'prev control_btn ' + _noPushPrevBtn()}
          onClick={toPrevPage}
          onKeyPress={toPrevPage}
        ></div>
        <div className="page_numbers">
          { _createNumbers() }
        </div>
        <div
          role="button"
          tabIndex="-3"
          className={'next control_btn ' + _noPushNextBtn()}
          onClick={toNextPage}
          onKeyPress={toNextPage}
        ></div>
      </div>
    </div>
  )
}
export default memo(Pagination)

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  maxPages: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
}
Pagination.defaultProps = {

}
