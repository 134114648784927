import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import SectionTag from './SectionTag'
import './SectionTags.sass'

const SectionTags = ({ tags, type, activeTag, spOnly }) => {
  const handleAllTagsClick = () => {
    const link = `/${type}`
    navigate(link)
  }
  const _renderTag = () => {
    if (tags && tags.length > 0) {
      return tags.map(tag => {
        if (tag.node.slug) {
          const { id, slug, name } = tag.node
          const isActive = slug === activeTag
          return <SectionTag slug={slug} name={name} isActive={isActive} type={type} key={id} />
        }
      })
    }
    return null
  }

  return (
    <ul className={`sectionTags flex jp-accent${spOnly ? ' spOnly' : ''}`}>
      {
        tags && tags.length > 0
          ? <li className={`sectionTag${!activeTag ? ' active' : ''}`} onClick={handleAllTagsClick}>すべて</li>
          : null
      }
      { _renderTag() }
    </ul>
  )
}
export default memo(SectionTags)

SectionTags.propTypes = {
  tags: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  activeTag: PropTypes.string,
  spOnly: PropTypes.bool
}
SectionTags.defaultProps = {
  spOnly: false
}
