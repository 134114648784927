import React from 'react'
import Img from '../Img'
import NoImgSrc from '../../assets/img/no_img.svg'

const NoImg = () => {
  return (
    <Img src={NoImgSrc} alt="画像なし" title="画像なし"/>
  )
}

export default NoImg
