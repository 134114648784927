import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, location } from 'gatsby'

import { getLikes, setLike } from '../services/likes'
import { LIKE_TYPES } from '../constants'

import PageWrapper from '../components/PageWrapper'
import SectionTitle from '../components/SectionTitle'
import Card from '../components/Card'
import SEO from '../components/SEO'
import Pagination from '../components/Pagination'
import SectionTags from '../components/SectionTags'

const BlogList = ({ data, pageContext }) => {
  const [likes, setLikes] = useState({})
  const [slugs, setSlugs] = useState([])
  const fetchData = async (slugs) => {
    try {
      const data = await getLikes(slugs, LIKE_TYPES.BLOG)
      setLikes(data)
    } catch (error) {
      console.warn(error)
    }
  }

  const refetchData = () => {
    fetchData(slugs)
  }

  useEffect(() => {
    // Get likes data
    const slugsArr = []
    for (const post of data.allWpBlogPost.edges) {
      if (post.node && post.node.slug) slugsArr.push(post.node.slug)
    }
    setSlugs(slugsArr)
    fetchData(slugsArr)
  }, [])
  const _renderCards = () => {
    if (data && data.allWpBlogPost && data.allWpBlogPost.edges && data.allWpBlogPost.edges.length > 0) {
      return data.allWpBlogPost.edges.map(({ node }) => {
        const { date, slug, title, acf_blog: { excerpt, mainImage, tags } } = node
        return (
          <Card
            key={slug}
            type="blog"
            slug={slug}
            title={title}
            excerpt={excerpt}
            image={mainImage}
            tags={tags}
            date={date}
            likes={(likes[slug] && likes[slug].count) || 0}
            like={(likes[slug] && likes[slug].like) || false}
            refetch={refetchData}
          />
        )
      })
    } else {
      return <p className="text-center">まだ記事は投稿されていません。</p>
    }
  }

  const _renderPagination = () => {
    if (pageContext && pageContext.currentPage) {
      const type = pageContext.tag ? `blog/tags/${pageContext.tag}` : 'blog'
      return <Pagination type={type} currentPage={pageContext.currentPage} maxPages={pageContext.numPages}/>
    }
    return null
  }
  return (
    <PageWrapper>
      <SEO title="ブログ" />
      <section className="blogPage">
        <SectionTitle title="Blog" subtitle="ブログ" />
        <SectionTags tags={data.allWpBlogTag.edges} activeTag={pageContext.tag} type="blog"/>
        <div className="cardsContainer flex">
          { _renderCards() }
        </div>
        { _renderPagination() }
        <SectionTags tags={data.allWpBlogTag.edges} activeTag={pageContext.tag} type="blog" spOnly/>
      </section>
    </PageWrapper>
  )
}
export default memo(BlogList)

BlogList.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
}

export const pageQuery = graphql`
    query blogListQuery($tag: String, $skip: Int, $limit: Int){
      allWpBlogPost(
        limit: $limit
        skip: $skip
        sort: {order: DESC, fields: date}
        filter: {acf_blog: {blogTags: {elemMatch: {slug: {eq: $tag}}}}}
      ){
        edges{
          node{
            id
            slug
            title
            date(formatString: "YYYY年MM月DD日")
            acf_blog {
              mainImage {
                title
                altText
                sourceUrl
              }
              excerpt
              tags: blogTags {
                name
                slug
                termTaxonomyId
                description
              }
            }
          }
        }
      }

      allWpBlogTag {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
`
